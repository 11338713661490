
// Ponderacion
.m-t-16 { margin-top: 16px; }
.m-b-16 { margin-bottom: 16px; }
.align-right { text-align: right; }
.valoracion-section { margin-left: 42px; }
.secciones-header {
  display: flex;
  justify-content: space-between;
}
.valoracion-error {
  color: red!important;
  border-color: red!important;
}

.secciones-header span:last-child, .container-actions b {
  color: #216b96;
}

.valoracion-section {
  & > .ponderacion-item input {
    height: 48px;
    font-size: 24px;
  }
  & > .section-container > .container-actions {
    font-size: 18px;
    margin-bottom: 32px;
  }
}
.ponderacion-item {
  display: flex;
  flex-direction: row;

  input[type="number"]::after {
    content: '%'
  }
  input:first-child {
    width: 80px;
    margin-right: 16px;
  }
  input:not(:first-child), select {
    flex: 1;
    margin-bottom: 0;
  }
}

.add-btn-section {
  text-align: center;
  margin: 24px 0;
}
.add-btn-section button, .add-btn-subsection button{
  margin: 0;
  padding: 0;
  & + button { margin-left: 16px; }
}

.section-container .container-actions {
  display: flex;
  align-items: center;

  .add-btn-subsection {
    position: relative;
    margin-left: 40px;
    flex: 1;
    height: 35px;

    button {
      position: absolute;
      opacity: 0;
      left: -40px;
      top: 0;
      transition: left 0.2s, opacity 0.2s;
    }
  }
}
.valoracion-subsection:hover > .section-container > .container-actions > .add-btn-subsection {
  button { opacity: 1; }
  button:first-child { left: 0; }
  button:not(:first-child) { left: 35px; }
}

.valoracion-field, .valoracion-subsection {
  z-index: 10;
  margin-top: 16px;
  .handler {
    position: absolute;
    top: 0;
    right: 100%;
    opacity: 0;
  }
}

.delete-button-ponderacion {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 100%;
  opacity: 0;
}

.valoracion-field:hover .handler, .valoracion-subsection:hover > .handler {
  opacity: 1;
  cursor: move;
}
.valoracion-field:hover .delete-button-ponderacion, .valoracion-subsection:hover > .delete-button-ponderacion {
  opacity: 1;
  cursor: pointer;
}

.valoracion-field {
  position: relative;
  .handler { padding: 29px 16px; }
  .new-field { margin: 0; }
  .delete-button-ponderacion { padding: 27px 16px; }
}

.valoracion-section > .ponderacion-item, .valoracion-subsection > .ponderacion-item { margin-bottom: 8px; }
.valoracion-subsection {
  position: relative;
  & > .handler { padding: 13px 16px; }
  & > .delete-button-ponderacion { padding: 11px 16px; }

  .section-container {
    padding: 16px 0 16px 42px;
    border-left: 2px solid $primary;
  }
}


// Valoracion
.nested-content {
  margin-left: 32px;
  input {
    height: 34px;
    min-width: 80px;
    margin: 4px 16px 4px 0;
  }
}
.primary-text { color: $primary; }
