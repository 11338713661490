.paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  color: $primary;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  
  button {
    -webkit-appearance: none;
    border: none;
    background: none;
    padding: 4px;
    outline: none;
    cursor: pointer;
    color: $primary;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .active {
    color: $black;
    font-weight: bold;
  }
}