.primary-btn {
  background-color: $primary;
  color: white;
  height: 38px;
  width: 166px;
  border-radius: 5px;
  font-size: 16px;
  outline-offset: -2px;
  border: none;
  cursor: pointer;
}

.secondary-btn {
  background-color: transparent;
  color: $gray1;
  height: 22px;
  // flex-basis: 65px;
  font-size: 16px;
  outline-offset: -2px;
  border: none;
  cursor: pointer;
}
