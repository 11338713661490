.modal-overlay {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
}

.modal-wrapper {
    position: fixed;
    background: white;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 30%;
    min-height: 250px;
    border-radius: 5px;
    padding: 8px 16px;
    box-sizing: border-box;

    i{
      font-size: 14px;
    }
    select{
      margin-top: 16px;
      margin-bottom: 16px;
      width: 100%;
    }

}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 8px;
  font-weight: 600;
}



.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid lightgrey;
  position: absolute;
  width: calc(100% - 64px);
  bottom: 8px;
  padding: 8px 16px 0 16px;
  
  & > * {
    transform: scale(0.85);
  }

}

.modal-close {
  background: none;
  border: none;
  padding: 0;
  font-size: 24px;
  outline: none;
}