
// react picker override styles
.react-datepicker-wrapper, 
table, 
.react-datepicker__input-container {
  width: 100%;

   + .error-message {
    display: block;
    position: absolute;
    font-size: 11px;
    color: red;
    bottom: 0;
  }
}

.text-title-red {
  color: red;
}

.react-datepicker__header--time {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__header {
  background-color: $primary-light;
  border-bottom: 1px solid $gray2;
}

.react-datepicker, 
.react-datepicker__time-container {
  border-color: $gray2;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: $primary !important;
}

.answer-form{

    .content {
        margin: 0;
        @media #{$phone}{
          width: 100%;
        }
    }
    .save {
        margin-top: 48px;
        text-align: right;
        .content{
            margin-right: 32px;
        }
      }
      
    .field-error{
      table {
        border-collapse: separate;
        border: 1px solid red;
        border-radius: 5px;
        transition: border 0.2s ease-in-out;
      }
    }

    .answer-field-container{
        display: flex;
        // overflow: auto;


        .col-left{
            flex-basis: 232px;
            flex-shrink: 0;
        }

        label{
            flex-grow: 1;
            
            &:hover, &:focus-within{
              .multiple-choice + .tooltip{
                opacity: 1;
              } 
            }
            
        }

        small {
          font-weight: bold;
          color: $gray1;
        }
    }
    .matrix-field-table{

      @media #{$phone}, #{$tablet}{
        width: 100%;
        th,td{
          display: table-cell
        }
      }
      width: calc(100% - 243px);

    }
    .answer-section-container{
      position: relative;
      p {
          margin: 0 0 16px 0;
          font-size: 18px;
          font-weight: 600;
          color: $gray1;
          width: 100%;
          
          @media #{$desktop} {
              max-width: 216px;
              position: absolute;
              top: 0%;
          }
      }
    }

    .checkbox {
      label{
        width: 100%;
        text-align: justify;
        justify-content: flex-end;
        @media #{$tablet}, #{$desktop}{
            margin-bottom: 16px;
            max-width: calc(100% - 264px);
        }
      }
    }
}

.answer-field-container {
  input:not([type="checkbox"])
  input:not([type="radio"]), select, textarea {
    width: 100% !important;
    
    @media #{$tablet}, #{$desktop} { 
      width: calc(100% - 264px) !important;
    }
  }
}


@media #{$phone} {
  .tooltip {
    width: 100%  !important;
    display: block;
    opacity: 1  !important;
    position: relative !important;

    &:before { content: unset !important }

    pre { margin: 0}
  }
}

.containerProgressBar{  
    position: relative;

    .progress-bar{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      
      li{
        width: 50px;
        list-style-type: none;
        float: left;
        position: relative;
  
        &:after{
          content: '';
          position: absolute;
          width:100%;
          height: 2px;
          background:$gray2;
          top: 50%;
          left: -100%;
          z-index: -1;
        }
  
        button {
          cursor: pointer;
          width: 25px;
          height: 25px;
          padding: 0;
          border: none;
          outline: none;
          color:white;
          border-radius: 50%;      
          text-align: center;
          font-size: 14px;
         
        }
  
        
      }
  
      li.active + li.active:after{
        background: $primary;
      }
      
      li:first-child:after{
        content: none;
      }
  
      .active{
        button{ background-color: $primary}
      }
      
      .no-active{
        button {background-color: $gray2}
      }
  
    }
  
  }

.answer-section-container .col-left {
  flex-basis: 216px !important;
  border-right: 2px solid #3491cc;
  margin-right: 16px;
}

.print-spinner{
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  background: white;
  top: 0;
  left: 0;

  .spinner{
    position: absolute;
    top: 50%;
    left: 50%;
  }

}

@media print{

  @page {
      size: auto;
      margin-left: 0;
      margin-right: 0;
  }

  .main{
    &:after{
      background: white;
    }
  }
  
  .print-spinner { display: none;}

  header {
    .icon-btn { display: none }
  }

  footer { display: none; }

  button { display: none; }

  
  .print-col-container{
    -webkit-print-color-adjust: exact;
    margin: 0 48px;
    position: relative;

    .fields-section, .answer-field-container {
      page-break-inside: avoid;
    }
    
    
    h1 { margin: 48px 0px;}
    .field-group{
        .matrix-field-table{
          width: 100%;
          border-collapse: collapse;
          th, td{
          display: table-cell;
          }
        }
        input:not([type="checkbox"]):not([type="radio"]), select, textarea {
          width: 100%;
        }

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .multiple-choice{
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;
      
        }
      
          .print-options{
            display: block !important;

          }
    }

    .page-break { 
        clear: both;
        height: 50px;
        page-break-before: always; 
    }

    .print-content{
          h1{
            margin: 16px 0px 32px 0px;
          }

        .tooltip{
            opacity: 1;
            width: 100%;
            position: relative;
            margin-bottom: 16px;
            margin-top: 16px;
            top: 0;
            transform: none;
        }

    }
  }
  .answer-field-container textarea.extensivetextfield {
    height: 1900px;
  }
}

.field-group{
  .print-options{
    display: none;
  }
}

.next-btn {
  display: flex;

  .content {
    text-align: right;
    margin-right: 32px;
  }
}

.cargo-title {
  margin-top: 0;
  margin-bottom: 0;
  &:before { content: unset}

  & + h1 {
    margin-top: 32px;
    margin-bottom: 48px;
  }

  & + p {
    margin-left: 65px;
  }
}