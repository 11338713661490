.spinner {
  border: 0.2em solid transparent;
  border-top-color: $primary-dark;
  border-left-color: $primary-dark;
  border-radius: 50%;
  display: none;
  margin: 0 20px;
  width: 20px;
  height: 20px;
  
  &.active {
    animation: spin 1s linear infinite;
    display: inline-block;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}