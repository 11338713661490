.new-field {
  display: flex;
  flex-direction: column;
  background: $primary-light;
  padding: 16px;
  box-shadow: 0 2px 14px -7px rgba(0,0,0,0.50);
  margin-bottom: 24px;
  margin-top: 10px;

  .field-header{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .move-to{
      margin-right: 16px;
      margin-bottom: 16px;
      padding: 8px;
      outline: none;
      background:none;
      border: none;
      color: #3292CC;
      cursor: pointer;
      font-size: 14px;
    }
    .helptext-icon{
      display: inline-block;
      margin: 10px;
      color: #3292CC;
      cursor: default;
    }
  }
  select {
    align-self: flex-end;
    width: 280px;
    margin-bottom: 16px;
  }

  label {
    font-size: 13px;
    color: $black;
  }

  .options {
    margin-top: 32px;
    border-top: 1px solid $gray2;
    display: flex;
    justify-content: flex-end;

    label {
      padding: 4px 8px 4px 0px;
    }

    & + .options {
      margin-top: 16px;
    }
  }

  .tooltip {
    display: flex;
    align-items: center;

    label {
      padding: 8px 0;
      border-top: 1px solid #D6D6D6;
      flex: 1;
    }
  }

  .delete-field{
      border: none;
      outline: none;
      background: transparent;
  }

  .select-values input{
    height: 30px;
    border: none;
    font-size: 13px;
    text-indent: 8px;
    border: 1px solid #e6eaee;
  }

  .tooltip-input {
    border: none;
    padding: 8px;
    font-size: 13px;
    text-indent: 0px;
    border: 1px solid #e6eaee;
  }

  .add-option {
    align-self: flex-start;
    border: none;
    background: none;
    font-size: 13px;
    margin: 8px 0;
    color: #3192cc;
    cursor: pointer;
  }

  .remove-btn {
    font-size: 24px;
    border: none;
    background: none;
    color: $orange;
    font-weight: bold;
    cursor: pointer;
  }
  .select-values {
    .value {
      display: flex;

      input { flex: 1 }
    }
  }

  .matrix{
    display: flex;

    .select-values{
      display: flex;
      flex-basis: 50%;

      label{
        flex-basis: 100%
      }
    }
  }



}

.matrix-field-table{
  margin: 16px 0;
  empty-cells: show;
  width: 100%;

  tr td input{
    width: 16px !important;
  }
}

.add-btn {
  top: 0;
  transition: top 0.2s ease-in-out;
  position: relative;
  padding: 8px 0;
}


.builder-field {
  z-index: 2;
  display: flex;
  background: white;

  > div { flex: 1; }

  &:hover {
    .handler-section { opacity: 1; }
  }

  .handler-section {
    margin: 8px;
    padding: 8px;
    opacity: 0;
    flex: 0;
    cursor: move;
  }


}

.drag , .drag-section {
  .handler-section { opacity: 1 !important; }
}

.validators, .conditionals {
  border-top: 1px solid #D6D6D6;
  padding-top: 8px;
}
.number-validator {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 13px;

  > * { margin-right: 16px !important;}

  select, input {
    margin: 0;
    width: auto;
    font-size: 13px;
    height: 30px;
    text-indent: 8px;
    border: 1px solid #e6eaee;
  }

  label {
    margin-bottom: 0;
    position: relative;

    &.field-error {
      input {
        border-color: red;
      }

      .error-message {
        width: 200%;
        bottom: -24px;
      }
    }
  }


}

.col-left{
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
  }

  .style-pages{
    font-size: 14px;
    color: $gray1;
    background-color: transparent;
    outline: none;
    border: none;
  }
  .single-page {
    @extend .style-pages;
    width: 232px;
    word-break: break-word;
    position: relative;
    padding: 8px 0 8px 8px;

    &.active{
      background-color:$primary-light;
      border-radius: 5px;
    }

    &:hover {
      .delete-page {
        opacity: 1!important;
      }
      .order-buttons {
        opacity: 1!important;
      }
    }
  }

  .nav-pages{
    @extend .style-pages;
    cursor: pointer;
    text-align: left;
    width: 210px;

  }

  .delete-page{
    @extend .style-pages;
    cursor: pointer;
    font-size: 20px;
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }


  .add-page {
    @extend .single-page;
    color: $primary;
    cursor: pointer;
    &:focus { background: none;}
  }

}

.page-title {
  margin-left: 42px;
  padding-bottom: 48px;

  input {
    height: 48px;
    font-size: 24px;
    width: 100%;
  }
}


.form-section{
  z-index: 999;
  margin-bottom: 48px;

  .section-title{
    display: flex;

    &:hover {
     .handler { opacity: 1; }
     .delete-section{ display: block}
    }



    .handler {
      margin: 0 8px 16px 8px;
      padding: 0 8px;
      flex: 0;
      opacity: 0;
      cursor: move;
    }


    .page-title{
      margin-left: 0px;
      flex-grow: 1;
      padding: 0px;
      margin-bottom: 24px;

      input{
        margin-top: 0px;
        height: 28px;
        color: $gray1;
        border: none;
        outline: none;
        text-indent: 0;
        padding: 0;
      }

      &.field-error{
        input{
          border-bottom: 1px solid red;
          border-radius: 0px;
        }
      }
    }

    .delete-section{
      border: none;
      outline:none;
      cursor: pointer;
      font-size: 20px;
      color: $orange;
      display: none;
    }
  }

.fields-section-container{
  border-left: 2px solid $primary;
  margin-left: 42px;

  .fields-in-section{
    display: flex;

    .fields { flex-grow: 1;}
  }
}

}

.icon-btn {
  border: none;
  cursor: pointer;
  margin-bottom: 4px;
  margin-right: 4px;

}

.add-btn-outside-sec{
  text-align: center;
}

.form-builder {
  height: 100%;

  .save {
    margin-top: 48px;
    text-align: right;
  }
}

.left-margin {
  margin-left: 42px;
}

.fields-container {

  &.dragging-section {
    & > .builder-field {
      opacity: 0.15;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &.dragging {
    & > .form-section {
      .builder-field {
        opacity: 0.15;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }
}

.feedback-error {
  font-weight: 600;
  color: red;
  margin: 0 20px;
}

.feedback-success {
  font-weight: 600;
  color: $green;
  margin: 0 20px;
}


.form-builder .col-left div.single-page, .valoraciones-design .col-left div.single-page {
  display: flex;
  align-items: center;

  .nav-pages {
    flex: 1;
    padding: 1px 4px 2px 8px;
  }

  .delete-page {
    position: inherit;
    transform: none;
    display: block;
    opacity: 0;
    height: 20px;
    padding: 0 8px;
  }

  .order-buttons {
    position: relative;
    width: 16px;
    height: 20px;
    opacity: 0;

    .order-button-up, .order-button-down {
      position: absolute;
      left: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      width: 16px;
      height: 16px;
      &::before {
        content: '';
        border: solid $gray2;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        position: absolute;
        left: 4px;
      }
      &:hover::before { border-color: $primary; }
    }
    .order-button-up {
      top: -5px;
      &::before {
        top: 6px;
        transform: rotate(225deg);
      }
    }
    .order-button-down {
      top: 11px;
      &::before {
        top: 2px;
        transform: rotate(45deg);
      }
    }
  }
}
