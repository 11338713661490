label {
  font-size: 15px;
  line-height: 20px;
  flex-direction: column;
  display: flex;
  margin-bottom: 28px;
  width: 100%;
  > * { margin-top: 8px;}
}

// Input
input:not([type="checkbox"]):not([type="radio"]), select {
  height: 42px;
  text-indent: 16px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid $gray2;
  background: white;
  font-size: 15px;
}

textarea {
  padding: 16px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid $gray2;
  background: white;
  font-size: 15px;
}

// error styles
.field-error {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 28px;

  input, select, textarea {
    transition: border 0.2s ease-in-out;
    border-color: red;

    & ~ span.error-message {
      display: block;
      position: absolute;
      font-size: 11px;
      color: red;
      bottom: 0;
    }
  }
}

.field-group {
  position: relative;
  .matrix-field-table + .tooltip{
    top: 0px;
    position: relative !important;
    width: 100%;
    display: inline-block;
    opacity: 1;
    transform: translateY(0%);
    &:before {
      opacity: 0;
    }
  }

  .table-scroll{
    width: 100%;
    overflow-x: auto;
  }

  .tooltip {
    width: 232px;
    position: absolute;
    box-sizing: border-box;
    right: 0;
    opacity: 0;
    transition: 0.2s opacity ease-in-out;
    padding: 16px;
    background: $gray2;
    margin-top: 10px;

    @media #{$tablet}, #{$desktop} { margin-top: 0px;}

    pre {
      width: 100%;
      word-break: break-word;
      white-space: pre-wrap;
      font-family: inherit;
    }

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 13px 8px 0;
      border-color: transparent $gray2 transparent transparent;
      left: -13px;
    }
  }

  .radio label, .checkbox label { width: calc(100% - 264px); }
  input:not([type="checkbox"]):not([type="radio"]), select, textarea {
    width: 100%;

    @media #{$tablet}, #{$desktop} { margin-right: 16px; width: calc(100% - 264px);}

    &:focus {
      & ~ span.tooltip {
        opacity: 1;
        z-index: 1000;
      }
    }
  }

  .multiple-choice {

    label {
      flex-direction: row-reverse;
      margin-bottom: 16px;
      padding: 0;
      flex-grow: 1;
    }

    & ~ span.error-message {
      display: block;
      position: absolute;
      font-size: 11px;
      color: red;
      bottom: 0;
    }
  }

  .input-others-disabled{
    opacity: 0.3;
    background-color: $gray3 !important;
  }
}

#input_perfil{
  width: 100%;
  @media #{$tablet}, #{$desktop} { width: calc(100% - 264px); }
}
