.form-flow {
  position: relative;

  &:before {
    content: " ";
    height: 100%;
    width: 3px;
    background: linear-gradient(
      to bottom,
      $primary-light,
      $primary-light 75%,
      white 75%,
      white
    );
    background-size: 100% 10px;
    left: 49px;
    position: absolute;
    z-index: -1;

    @media #{$phone-up} { left: 66px; }
  }

  .step {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
  
    .circle {
      width: 100px;
      height: 100px;
      background: $primary-light;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      margin-right: 16px;
      flex-shrink: 0;
      
      img { width: 40px; }

      @media #{$phone-up} { 
        width: 134px; height: 134px;

        img { width: 50px; }
      }
    }
  
    p {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
      white-space: pre-wrap;
    }

    a {
      white-space: pre-wrap;
    }
  
    .inactive {
      font-size: 14px;
      line-height: 19px;
      color: $gray1;
    }
  
    .actions {
      list-style: none;
      padding: 0;
      margin: 0;

      @media #{$tablet} {
        display: flex;
        flex-wrap: wrap;
      }
      
      li {
        white-space: nowrap;
        margin-bottom: 4px;

        @media #{$phone-up} {
          display: inline;
          margin-right: 16px;
        }
      }

      .disabled-link{
        display: none;
      }
    }
  }
}

.test_answers {
  margin-top: 8px;
  display: block;
}

.enable-vals {
  margin: 42px 0;
  padding: 16px 0;
  background: white;
  
  button {
    color: $primary;
  }
}

.inline-link {
  margin: 8px;
}

.val-error {
  font-size: smaller;
  color: red;
}

.val-success {
  font-size: smaller;
  color: green;
}

.col-left {
  .info {
    a {
      font-weight: normal;
      margin: 8px 0 0 0;
    }
  }
}


div.nivel {
  display: flex;
  align-items: center;
  padding: 16px 0;
  justify-content: center;
}

div.nivel-container {
  width: 48px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 16px;
}

span.nivel-circle.full {
  background: #3292cc;
}
span.nivel-circle {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #e6e8e8;
  border-radius: 50%;
  margin: 1px;
}

.estructura {


  thead { border-bottom: 1px solid #d6d6d6 }
  table { border: 1px solid #d6d6d6 }
  th { width: 250px;}
  
  td {   
    vertical-align: baseline;
    
    &:first-child {
      border-right: 1px solid #d6d6d6;
      font-weight: bold;
    }
  }

  li { margin-bottom: 8px;}
}

.scroll-table{
  width: 100%;
  overflow-x: scroll;

  table {
    td, th {
      display: table-cell
    }
  }
}

.content {
  width: 100%;
}