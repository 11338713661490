// Header styles
header {
  display: flex;
  flex-basis: 89px;
  flex-shrink: 0;
  background-color: $primary-light;
  padding: 0 48px;
  justify-content: space-between;
  align-items: center;
  background: white;

  @media #{$desktop} {
    justify-content: flex-start;
    padding: 0 88px;
  }

  .logo {
    flex-basis: 232px;
  }

  nav {
    display: none;
    margin: 0px 16px;
    flex: 1;

    a {
      margin-right: 32px;
      color: $black;
      font-weight: 600;
    }

    @media #{$desktop} { display: flex; }
  }

  .icons {
    display: none;
    flex-shrink: 0;
    justify-content: flex-end;

    button + button {
      margin-left: 16px;
    }

    @media #{$desktop} { display: flex; }
  }

  .menu {
    @media #{$desktop} { display: none;}
  }
}

.menu_out {
  @media #{$desktop} { height: 0}
  width: 100%;
  position: relative;
  z-index : 1;
  padding-left: 20px;
  background-color: $primary-light;
  background: white;

}

.menu_in{
  width: 90%;
  padding: 20px;

  span{
    color: rgb(60, 158, 212)
  }
}
