footer {
  color: white;
  background-color: $primary;
  flex: 0 0 200px;
  padding: 0 45px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  top: -2 * 48px - 32px;

  > div { padding-bottom: 48px; }

  @media #{$tablet} { padding: 0 48px; }
  @media #{$desktop} { padding: 0 88px; }

  .left {
    display: flex;
    align-items: center;

    img { margin-right: 16px;}

    p { margin: 0; font-size: 14px; }
  }

  .right {
    a {
      color: white;
      text-decoration: none;
      font-size: 14px;
      line-height:25px;
    }
  }
}