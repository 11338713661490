body {
  font-family: 'Open Sans', sans-serif; 
  -webkit-font-smoothing: antialiased;
}

h1 {
  position: relative;
  font-size: 24px;
  line-height: 33px;
  margin: 48px 24px;
  padding-left: 10px;
  font-weight: 600;

  @media #{$desktop} { margin: 48px 40px; }

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    height: 26px;
    background-color: $orange;
    width: 2px;
    top: 50%;
    transform: translateY(-50%);
  }
}

a {
  color: $primary;
  font-size: 14px;
  line-height: 19px;
  text-decoration: none;
}

.label {
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  color: $gray1;
}

.text_info{
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  color: $gray1;
}