.wizard-btn {
    position: absolute;
    right: 0;
    margin: 0 75px;
    top: 53px;
    z-index: 999;

    .primary-btn {
        display: block;
        text-align: center;
        line-height: 38px;
        margin-bottom: 16px;
    }
}

.wizard-multi-select{
    display: flex;
    span{
        width: 8px;
        margin-right: 8px;
        align-self: center;
    }
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 450px;
    }

    @media print{
        p{
            padding-left: 8px;
            white-space: normal;
            overflow: auto;
            text-overflow: unset;
            max-width: 100%;
        }
    }
}

.answers-table {
    margin: 40px 0;
    tbody > tr {
        background: white;
    }
}

td > pre {
    font-family: inherit;
    margin: 4px 0;
}

.buttons {
    width: calc(100% - 264px);
    text-align: center;

    > * {
        margin: 0 16px;
    }
}

.profile-print {
    display: none;

    
    .answer-entry {
        margin: 8px 16px;
        padding: 8px;
        border-bottom: 1px solid $gray1;
    }
    
    .page-break { 
        clear: both;
        height: 50px;
        page-break-before: always; 
    }
    
    @media print {
        display: block;
        h1 {
            margin: 0 24px;
        }
        table{
            th,td{
                display: table-cell;
            }
        }
    }
}

.answer-form.profile {
    @media print {
        display: none;
    }
}

.general-info {
    margin: 32px 260px 32px 0;
}

@media print {
    header {
        flex-basis: 60px;
    }
}

.right-spinner {
    position: absolute;
}