.checkbox {
  display: inline-flex;
  align-items: center;

  label {
    flex-shrink: 0;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }

  input {
    position: relative;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    appearance: none;
    background: white;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #e6eaee;
    transition: all .2s;
    margin: 8px;

    &::before {
      content: ' ';
      position: absolute;
      top: 3px;
      left: 2px;
      width: 8px;
      height: 4px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(0deg);
      transition: all .2s;
    }

    &:checked {
      border: 2px solid $primary-dark;
      background: $primary-dark;
      transition: all .2s;

      &::before {
        transform: rotate(-45deg);
      }
    }

    &:disabled {
      border-color: #e6eaee;
      cursor: default;

      &:checked {
        border-color: #e6eaee;
        background-color: $gray2;
      }
      &:not(:checked) {
        background-color: $gray3;
        &::before {
          border-left-color: $gray3;
          border-bottom-color: $gray3;
        }
      }

      + .checkbox label {
        color: gray;
        cursor: default;
      }
    }
  }
}

.info-radio {
  display: inline-flex;
  background-color: #F4F8F9;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px;
  margin: 3px 0px;
}

.info-radio-arrow {
  display: inline-flex;
  margin-bottom: -4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #F4F8F9 transparent transparent;
}

.radio {
  display: inline-flex;
  align-items: center;

  label {
    flex-shrink: 0;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0;
    flex-direction: row;
    align-items: center;
  }

  input {
    position: relative;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background: white;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #e6eaee;
    transition: all .2s;
    margin: 8px;
  }
}
