 
// Screen size breakpoints
$phone : "only screen and (max-width: 767px)";
$phone-up: "only screen and (min-width: 768px)";
$tablet : "only screen and (min-width: 768px) and (max-width: 1279px)";
$desktop : "only screen and (min-width: 1280px)";


// Colors
$primary : #3292CC;
$primary-dark : #216B96;
$primary-light : #F4F8F9;
$orange : #EE803E;
$green : #A3C555;
$black : #38393A;
$gray1 : #ACACAC; 
$gray2 : #D6D6D6; 
$gray3 : #ECEDED; 
