.login {
  height: 100%;

  &:after {
    content: " ";
    height: calc(50% - 96px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $primary;
    z-index: -1;
  }

  .header {
    padding: 32px 88px;
    color: $primary;
    display: flex;
    font-size: 14px;
    line-height: 25px;
    
    > img { margin-right: 16px;}
  }
  
  h1 { margin: 0 0 32px 0;}

  .form {
    width: 80%;
    @media #{$desktop}, #{tablet} { width: 500px; }
    background: white;
    padding: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &:after {
      background: linear-gradient(
        to right,
        $orange 33%, $green 33%,
        $green 66%, $primary-dark 66%,
        $primary-dark 100%
      );
      position: absolute;
      content: '';
      height: 5px;
      right: 0;
      left: 0;
      bottom: 0;
    }

    button {
      float: right;
    }
    
    .wrong-credentials {
      font-size: 13px;
      color: red;
      line-height: 35px;
    }
  }
}

.page-title-nav {
  h1 { margin: 0; margin-bottom: 16px }

  margin: 48px 24px;
  @media #{$desktop} { margin: 48px 40px; }

  a {
    position: relative;
    margin-left: 20px;

    &:before {
      content: '';
      border: solid #3292cc;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      position: absolute;
      transform: rotate(135deg);
      left: -12px;
      top: 6px;
    }
  }
}

.description-save {
  text-align: right;
  margin: 0;
  .content {
    margin: 0
  }
}
header {
  .icons {
    span {
      color: #3292CC;
      font-size: 14px;
      line-height: 19px;
      text-decoration: none;
      cursor: pointer;
    }
  }
}