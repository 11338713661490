table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;

  thead {
    tr {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      border-bottom: 1px solid #979797;
    }
  }

  tr {
    height: 42px;
    border-bottom: 1px solid $gray2;
    
    &:nth-child(2n) { background: $primary-light;}
  }

  td, th {
    padding: 0 8px;
    display: none;
    
    &:first-child { display: table-cell; }
    
    &.visible { display: table-cell; }

    @media #{$tablet} {
      &:nth-child(2) { display: table-cell; }
    }
    @media #{$desktop} {
      display: table-cell;
    } 
  }

  &.has-actions {
    td, th {
      &:last-child {
        display: table-cell !important; 
      }
    } 
  }
}

.badge {
  width: 200px;
  height: 26px;
  border-radius: 20px;
  color: white;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  line-height: 26px;

  &.green { background: $green}
  &.gray { background: $gray1}
  &.blue { background: $primary-dark}
  &.orange { background: $orange}

}