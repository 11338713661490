.validation {

    .content{
        position: relative;
    }

    .feedback-error {
        font-weight: 0;
        font-size: 12px;
        color: red;
        margin: 16px 0;
        position: absolute;
        top: 60%;
        left: 5%;

        @media #{$tablet}, #{$desktop}{
            top: 50%;
            left: 2%;
        }
      }

      .feedback-success {
          @extend .feedback-error;
          color: $green;
      }

      .val-actual{
          a{
              font-size: 15px;
            }
      }
    
    .file-form {
        background-color: $primary-light;
        display: flex;
        flex-direction: column;
        margin-top: 48px;
        margin-bottom: 32px;
        border-radius: 5px;
        padding: 24px 22px 24px 22px;
        
        @media  #{$tablet}, #{$desktop} {
            flex-direction: row;
        }
        
          input{
            width: 100%;
            margin-bottom: 16px;
            @media #{$tablet}, #{$desktop}{
                flex-basis: 253px;
                flex-shrink: 1;
            }
        }

        .file-input{
            display: flex;
            flex-direction: column;

            @media #{$tablet}, #{$desktop} {
                flex-direction: row;
                flex-basis: 435px;
            }
            .file{
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }

            .file + label {
                flex-basis: 38px;
                margin-bottom: 16px;
                background-color: $primary;
                color: white;
                border-radius: 5px;
                font-size: 16px;
                border: none;
                align-items: center;
                justify-content: center;

                &:active{
                    outline: 2px solid #4D90FE;
                    -webkit-box-shadow: 0px 0px 5px  #4D90FE;
                    box-shadow: 0px 0px 5px  #4D90FE;
                    outline-offset: -2px;

                }
                
                @media #{$tablet}, #{$desktop} {
                    height: 38px;
                    flex-basis: 166px;                    
                    margin: 2px 0 2px 16px;
                }
            }

        }

        button{
            margin-bottom: 16px;
            width: 100%;

            @media #{$tablet}, #{$desktop} {
                width: 166px;
                margin: 2px 0 2px 16px;
            }
        }
    
    }
    
    p {
        color: $black;
        font-size: 15px;
    }

    .go-back{
        margin-top: 48px;
        text-align: center;
    }

    .profile-error {
        font-weight: 0;
        font-size: 12px;
        color: red;
        margin: 16px 0;
    }
}


