.valoracion {
  h3 {
    margin: 0;
    font-size: 20px;
  }
  .nested-content { margin-left: 24px; }
  .valoracion-detail-section + .valoracion-detail-section {
    margin-top: 40px;
  }
  .valoracion-detail-subsection + .valoracion-detail-subsection,
  .valoracion-detail-subsection + .valoracion-row,
  .valoracion-row + .valoracion-detail-subsection {
    margin-top: 16px;
  }
  .valoracion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: $primary;
    padding: 4px 8px 4px 0;
  }
  .valoracion-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    &:nth-child(2n) {
      background: $primary-light;
    }
  }
  .primary-btn {
      display: block;
      text-align: center;
      line-height: 38px;
      margin-bottom: 16px;
  }
  .col-container{
    .col-left{
      flex-direction: column;
    }
  }  
}

.valoracion, .scores{
  padding: 32px;


  h4 { margin: 0 }

  input {
    width: 64px;
    margin: 4px 16px;
    height: 32px;
    text-indent: 12px;
    flex: 0 0 64px;
  }
  .save {
    justify-content: flex-end;
  }
}

.valoracion {

  h1 {
    @media print {
      margin: 0 0 20px 0;
    }
  }

  .total {
    color: #A3C555;
    .label { color: #A3C555;}
  }

  .page {
    padding: 32px;
    padding-top: 0;
    border-bottom: 1px solid $primary-light;

    .section {
      padding-left: 32px;
    }

    & + .page {
      padding-top: 32px;
    }
  }

  .leaf {
    padding-left: 32px;
    flex-wrap: nowrap !important;
    margin-bottom: 16px;
  }

  .leaf, .node {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .col-container {
    @media only screen and (max-width: 1279px) {
      flex-direction: column;

      .col-left {
        display: flex !important;
        justify-content: space-around;
        flex-basis: 0%;
      }
    }

    @media print {
      flex-direction: column;

      .col-left {
        display: flex !important;
        justify-content: space-around;
        flex-basis: 0%;
      }
    }


    small {
      flex-basis: 100%;
      padding-left: 48px;
      color: #216b96;
      font-weight: bold;
      margin: 8px 0;
      display: block;

      &.error { color: #ef7f3e; }
    }
  }

  h2 {
    margin-left: 0;
    border-bottom: 2px solid #3292cc;
    line-height: 50px;
    font-weight: 600;
    margin-right: 8px;
  }

  h3 {
    color: #3292cc;
  }
}

.scores {

  .score-leaf, .values {
    padding: 8px 32px;
  }

  .score-value {
    display: flex;
  }
  .separated {
    margin: 16px;
    display: inline-flex;
  }
}

.validation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 32px;

  &:nth-child(2n) {
    background: $primary-light;
  }

  > div + div {
    font-weight: bold;
  }

  .subtotal {
    font-weight: bold;
    color: $primary
  }
}

.profile-row {
  margin: 8px 8px 8px 32px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f1f1f1;
  padding: 8px 0;

  > div {
    font-weight: bold;
  }
  > div + div {
    font-weight: normal;
    margin-top: 8px;
  }
}
