.filters {
  background: #F4F8F9;
  padding: 24px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 32px;
  flex-direction: column;
  

  @media #{$tablet}, #{$desktop} {
    flex-direction: row;
  }

  input, select { 
    width: 100%;
    margin-bottom: 16px;
    @media #{$tablet}, #{$desktop} {
      margin: 5px;
    }
  }

  button {
    margin: 5px;
    min-width: 100px;
    @media #{$phone} {
      width: 100%;
    }
  }

  @media #{$tablet}{
    //padding: 42px 24px;

    input, select { 
      width: 100%;
    }

    .filter-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;

      .filter-pair {
        display: flex;
        flex-direction: row;
      }
    }

    .filter-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 90px;

      .primary-btn{
        margin: 0px;
      }

      .secondary-btn{
        width: 164px;
        margin-bottom: 10px;
      }
    }
  }

  @media #{$desktop}{
    .filter-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;

      .filter-pair {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
    }

    .filter-buttons {
      display: flex;
      flex-direction: column;
    }
  }
}