html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  background-color: $primary-light;
  margin: 0;
}

.container-full {
  margin: 0 24px;

  @media #{$desktop} { margin: 0 40px; }
  
}

// Layout styles
.col-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  flex-wrap: wrap;
  @media #{$desktop}, #{$tablet} { flex-direction: row }
  @media #{$desktop} { margin: 0 40px; }
}

.col-left {
  flex-basis: 232px;
  flex-shrink: 0;
  display: none;

  @media #{$desktop} { display: block;}
}

.mobile-col-left {
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media #{$desktop} { display: none;}

  div{
    padding-top: 5px;
  }
}

.content {
  flex: 1;

  @media #{$tablet} { margin: 0 16px 0 0; }
  @media #{$desktop} { margin: 0 16px; }
}


.col-right {
  @extend .col-left;

  @media #{$tablet} { display: block;}
  @media #{$desktop} { display: block;}
}


.main {
  background: white;
  flex: 1 0 calc(100% - 209px);
  margin: 24px;
  padding-bottom: 48px;
  position: relative;
  z-index: 1;

  &:after {
    background: linear-gradient(
      to right,
      $orange 33%, $green 33%,
      $green 66%, $primary-dark 66%,
      $primary-dark 100%
    );
    position: absolute;
    content: '';
    height: 5px;
    right: 0;
    left: 0;
    bottom: 0;
  }
  @media #{$desktop} {
    margin: 48px;
    flex: 1 0 calc(100% - 257px);
  }
}

.icon-btn { 
  border: none;
  background: transparent;
}

// Estilos para la información de la sidebar.
.col-left {
  .info {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
